// /var/www/afra/web-design.rampagesoft.com/my-app/src/App.js


import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/main.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./css/rampagesoft.css";
import Menu from "./menu";

import MetaTAG from "./meta-tag";
import MetaTagCRM from "./meta-tag-crm";

import Banner from "./banner";
import Text5icon from "./component/text5icon.js";
import Detailphoto from "./component/detailphoto.js";
import Bannergarantee from "./bannergarantee";
import Detailphoto2 from "./component/detailphoto2.js";
import Spahotel from "./component/spahotel.js";
import Bgbanner from "./component/bg-banner.js";
import Packagewebsite from "./component/package-website.js";
import Slidebar from "./component/slidebar.js";
import FAQ from "./component/faq.js";
import TTTRAM from "./tttrampage";
import Footer from "./footer";

// Import the newly created pages
import CRM from './page/CRM.js';
import Ecomm from './page/Ecomm.js';
import icontextData from "./data/icontextData.json";
import successStoriesData from "./data/successStories.json";
import bgBannerContent from "./data/bgBannerContent.json";
import detailPhotoContent from "./data/detailPhotoContent.json";

import spaHotelContent from "./data/spaHotelContent.json";
import appPackageContent from "./data/appPackageContent.json";
//import faqData from "./data/faqData.json";


// Dynamically load images by prepending the correct path
const updatedCrmPackageContent = {
  ...appPackageContent,
  packages: appPackageContent.packages.map(pkg => ({
    ...pkg,
    image: require(`./media/${pkg.image}`)  // Prepend media path
  }))
};

function App() {
  const [faqData, setFaqData] = useState(null);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/data/faqData.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => setFaqData(data))
      .catch((error) => console.error("Error fetching faqData:", error));
  }, []);
  

  if (!faqData) {
    return <div>Loading...</div>; // Show a loading state while data is being fetched
  }
  
  return (
    <Router basename="/webdesign">
      {/* <Router basename="/"> */}
      <div>
        <header>
          <Menu />
        </header>
        <main>
          <Routes>

            <Route
              path="/"
              //path="/webdesign/*"
              element={
                <>
                  <MetaTAG />
                  <Banner />
                  <Text5icon data={icontextData.services || []} headingText={icontextData.headingText} />
                  <Detailphoto pageType="design" />
                  <Bannergarantee />
                  <Detailphoto2 content={detailPhotoContent} />
                  <Spahotel content={spaHotelContent} />
                  <Bgbanner heading={bgBannerContent.heading} />
                  <Slidebar data={successStoriesData.services || []} heading={successStoriesData.headingText} />

                  {/* <Packagewebsite content={appPackageContent} /> */}
                  <Packagewebsite content={updatedCrmPackageContent} />
                  <FAQ content={faqData} />
                  <TTTRAM />
                </>
              }
            />

            <Route path="/crm" element={
                <>
                  <MetaTagCRM />
                  <CRM />
                </>
              } /> 
              
              {/* Replace with CRM component */}
            <Route path="/ecomm" element={<Ecomm />} />


            {/* Define other routes here */}


          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
