import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTagCRM = () => {
  return (
    <Helmet>

      {/** TITLE */}
      <title>รับทำ CRM พัฒนาระบบ CRM Web Application CRM Developer RAMPAGESOFT</title>

      {/** FAVICON */}
      <link rel="icon" type="image/png" href="https://afaa.website/s/ff6970.png" />

      {/** HTTP-EQUIV */}
      <meta httpEquiv="revisit-after" content="3 days" />
      <meta httpEquiv="Cache-control" content="public" />
      <meta httpEquiv="imagetoolbar" content="yes" />

      {/** OWNERSHIP & AUTHOR */}
      <meta name="copyright" content="AFRA APACHE" />
      <meta name="owner" content="AFRA APACHE" />
      <meta name="web_author" content="AFRA APACHE" />
      <meta name="author" content="AFRA APACHE" />

      {/** DESCRIPTION */}
      <meta
        name="description"
        content="รับทำ CRM พัฒนาระบบ CRM Web Application ตอบโจทย์ทุกธุรกิจ ช่วยจัดการงานขาย การตลาด คำสั่งซื้อ และบริการลูกค้า ให้มีประสิทธิภาพสูงสุด พร้อมฟีเจอร์สำคัญ เช่น Dashboard, POS, การจัดการคำสั่งซื้อ, และการรายงานผลแบบเรียลไทม์ โดยทีมงาน RAMPAGESOFT"
      />

      {/** KEYWORDS */}
      <meta
        name="keywords"
        content="
          รับทำ CRM,
          พัฒนาระบบ CRM,
          CRM Developer,
          Web Application CRM,
          RAMPAGESOFT,
          Custom CRM,
          วางระบบทำ CRM,
          ระบบ CMS,
          ระบบจองออนไลน์,
          POS,
          CRM Marketing,
          CRM Booking,
          CRM Solutions
        "
      />

      {/** NEWS KEYWORDS */}
      <meta
        name="news_keywords"
        content="
          รับทำ CRM,
          พัฒนาระบบ CRM,
          CRM Developer,
          Web Application,
          RAMPAGESOFT
        "
      />

      {/** OPEN GRAPH */}
      <meta property="og:title" content="รับทำ CRM พัฒนาระบบ CRM Web Application CRM Developer RAMPAGESOFT" />
      <meta
        property="og:description"
        content="รับพัฒนาระบบ CRM ที่ออกแบบมาเพื่อตอบสนองความต้องการของธุรกิจทุกขนาด ด้วยฟีเจอร์ครบครัน ทั้ง Dashboard, Calendar, POS, Orders Management, และ Reports Analyze พร้อมทีมงานมืออาชีพจาก RAMPAGESOFT"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://rampagesoft.com/crm" />
      <meta property="og:image" content="https://afaa.website/s/ff6970.png" />
      <meta property="og:site_name" content="web-design.rampagesoft.com" />
      <meta property="og:locale" content="th_TH" />
      <meta property="og:image:secure_url" content="https://afaa.website/s/ff6970.png" />

      {/** OTHERS */}
      <meta name="generator" content="react" />
      <meta name="distribution" content="Global" />
      <meta name="rating" content="General" />
      <meta name="expires" content="Never" />

      {/** CANONICAL & ALTERNATE LINKS */}
      <link rel="canonical" href="https://rampagesoft.com/crm" />
      <link rel="alternate" hrefLang="en" href="https://rampagesoft.com/crm" />
      <link rel="alternate" hrefLang="th" href="https://rampagesoft.com/crm" />

      {/** TWITTER CARD */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@AFRAAPACHE" />
      <meta name="twitter:domain" content="https://rampagesoft.com/crm" />
      <meta
        name="twitter:title"
        content="รับทำ CRM พัฒนาระบบ CRM Web Application CRM Developer RAMPAGESOFT"
      />
      <meta
        name="twitter:description"
        content="ระบบ CRM และ CMS ที่ปรับแต่งได้ตามความต้องการ พร้อมเครื่องมือการตลาด การจัดการลูกค้า และการวิเคราะห์ข้อมูลแบบเรียลไทม์"
      />
      <meta name="twitter:image" content="https://afaa.website/s/ff6970.png" />

      {/** APPLE WEB APP */}
      <meta name="apple-mobile-web-app-title" content="รับทำ CRM พัฒนาระบบ CRM Web Application CRM Developer RAMPAGESOFT" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-touch-fullscreen" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <link href="https://afaa.website/s/ff6970.png" rel="apple-touch-icon" type="image/png" />
      <link href="https://afaa.website/s/ff6970.png" rel="apple-touch-startup-image" />

      {/** MICROSOFT TAGS */}
      <meta name="mssmarttagspreventparsing" content="true" />
      <meta content="IE=edge,chrome=1" httpEquiv="X-UA-Compatible" />
      <meta name="msapplication-starturl" content="https://rampagesoft.com/crm" />
      <meta name="msapplication-window" content="width=800;height=600" />
      <meta name="msapplication-navbutton-color" content="black" />
      <meta name="application-name" content="CRM RAMPAGESOFT" />
      <meta name="msapplication-tooltip" content="CRM RAMPAGESOFT" />

    </Helmet>
  );
};

export default MetaTagCRM;
